import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/work-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul className="work-links">
  <li>
    <a href="http://bereansatthegate.com">
      <i className="fas fa-globe-americas fa-lg"></i>
      View site
    </a>
  </li>
  <li>
    <a href="https://github.com/SchanelyStudios/bereans-theme">
      <i className="fab fa-github fa-lg"></i>
      View Github Repo
    </a>
  </li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/images/work/bereans.png",
        "alt": null
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      